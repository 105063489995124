import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Home } from "./components/Home";
import './App.css';

function App() {
  return (
    <div className="App">
         <Home />
    </div>
  );
}

export default App;
